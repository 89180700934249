<template>
    <default-template
        title="温熱・省エネ設備機器ポータルサイト(住宅版)"
        :is-visible-public="isOrganization"
        :is-visible-logout="!isPublic"
        :is-visible-pass-update="!isPublic"
        :is-visible-jigyosya-login="isPublic"
        :is-house="true"
        :is-visible-breadcrumb="true"
        breadcrumb-category="電気ヒートポンプ給湯機一覧"
        :breadcrumbItem="packageName"
        :is-visible-user-header="isOrganization"
        :is-visible-administrator-header="isAdministrator"
    >
        <!-- データ取得中は非表示にする -->
        <div v-if="!isInProgress" class="bl_contents_wrapper">
            <div class="bl_underHeader ly_underHeader">
                <h2 class="el_lv2Heading">
                    電気ヒートポンプ給湯機
                    <span v-if="isDetail">
                        詳細
                    </span>
                    <span v-if="isDelete">
                        削除
                    </span>
                    <span v-if="isEnabled">
                        承認
                    </span>
                </h2>
            </div>
            <div
                v-if="isPublic"
                key="isPublic"    
            >
                選択されたパッケージ：
                <span
                    ref="jest-electric-heat-pumps-detail-package-name"
                >
                    {{ packageName }}
                </span>
                <app-button
                    name="コピー"
                    @click="copy"
                    ref="jest-electric-heat-pumps-detail-copy-button"
                    class="inline outlined wd_100px uq_btnCopy"
                />
            </div>
            <div
                v-if="isDelete"
                key="isDelete"
            >
                <p
                    v-if="isSoftDelete"
                    class="red_bold"
                >
                    削除処理後、以下全てが共通する機器は登録できなくなります。<br>
                    本当に削除しますか？<br>
                    ブランド事業者名：{{brandName}}<br>
                    システム形式：{{electricHeatPumpsDetail.system_number}}<br>
                    ヒートポンプユニット形式：{{electricHeatPumpsDetail.pu_number}}<br>
                    貯湯ユニット形式:{{electricHeatPumpsDetail.tu_number}}<br>
                    成績証明書番号または自己適合宣言書番号（試験品質）:{{electricHeatPumpsDetail.certificate_number}}<br>
                </p>
                <p v-else>以下の機器を削除します。よろしいですか？</p>
            </div>
            <div
                v-if="isEnabled"
                key="isEnabled"
            >
                <p>以下の機器を承認します。よろしいですか？</p>
            </div>
            <div class="uq_width_fitter bl_eleHeatPump_table">
                <table>
                    <!-- ラベル部分 -->
                    <tr>
                        <th colspan="3">
                            項目名
                        </th>
                        <th>
                            データ
                        </th>
                    </tr>
                    <!-- 表の中身 -->
                    <device-table-row
                        name="ブランド事業者名"
                        :value="brandName"
                        :name_colspan="3"
                        ref="jest-electric-heat-pumps-detail-brand-name"
                    />
                    <device-table-row
                        name="製造事業者名"
                        :value="electricHeatPumpsDetail.pu_manufacturer_name"
                        :name_colspan="3"
                        ref="jest-electric-heat-pumps-detail-pu-name"
                    />
                    <device-table-row
                        name="システム形式"
                        :value="electricHeatPumpsDetail.system_number"
                        :name_colspan="3"
                        ref="jest-electric-heat-pumps-detail-system-number"
                    />
                    <device-table-row
                        name="ヒートポンプユニット形式"
                        :value="electricHeatPumpsDetail.pu_number"
                        :name_colspan="3"
                        ref="jest-electric-heat-pumps-detail-pu-number"
                    />
                    <device-table-row
                        name="貯湯ユニット形式"
                        :value="electricHeatPumpsDetail.tu_number"
                        :name_colspan="3"
                    />
                    <device-table-row
                        v-if="!isPublic"
                        name="情報公開年月日"
                        :value="date(electricHeatPumpsDetail.publish_date)"
                        :name_colspan="3"
                    />
                    <device-table-row
                        name="発売予定年月日"
                        :value="date(electricHeatPumpsDetail.release_date)"
                        :name_colspan="3"
                    />
                    <device-table-row
                        name="性能確認区分"
                        :value="electricHeatPumpsSystemCertificates.performance_validation_category"
                        :name_colspan="3"
                    />
                    <device-table-row
                        name="成績証明書番号または自己適合宣言書番号（試験品質）"
                        :value="electricHeatPumpsDetail.certificate_number"
                        :name_colspan="3"
                    />
                    <device-table-row
                        name="試験時の消費電力"
                        algebra="中間期標準"
                        :value="electricHeatPumpsSystemCertificates.P_HP_imd_std_test"
                        :name_rowspan="3"
                        :name_colspan="2"
                    />
                    <device-table-row
                        algebra="夏期標準"
                        :value="electricHeatPumpsSystemCertificates.P_HP_sum_std_test"
                        :name_colspan="2"
                    />
                    <device-table-row
                        algebra="冬期標準"
                        :value="electricHeatPumpsSystemCertificates.P_HP_win_std_test"
                        :name_colspan="2"
                    />
                    <device-table-row
                        name="試験時の加熱能力"
                        algebra="中間期標準"
                        :value="electricHeatPumpsSystemCertificates.q_HP_imd_std_test"
                        :name_rowspan="3"
                        :name_colspan="2"
                    />
                    <device-table-row
                        algebra="夏期標準"
                        :value="electricHeatPumpsSystemCertificates.q_HP_sum_std_test"
                        :name_colspan="2"
                    />
                    <device-table-row
                        algebra="冬期標準"
                        :value="electricHeatPumpsSystemCertificates.q_HP_win_std_test"
                        :name_colspan="2"
                    />
                    <device-table-row
                        name="試験時の着霜期高温エネルギー消費効率"
                        :value="electricHeatPumpsSystemCertificates.e_HP_def_high_test"
                        :name_colspan="3"
                    />
                    <device-table-row
                        name="試験時の着霜期高温ヒートポンプ運転時エネルギー消費効率"
                        :value="electricHeatPumpsSystemCertificates.e_HP_frst_high_test"
                        :name_colspan="3"
                    />
                    <device-table-row
                        name="試験時の沸上げ温度"
                        algebra="着霜期高温"
                        :value="electricHeatPumpsSystemCertificates.theta_bw_frst_high_test"
                        :name_rowspan="4"
                        :name_colspan="2"
                    />
                    <device-table-row
                        algebra="中間期標準"
                        :value="electricHeatPumpsSystemCertificates.theta_bw_imd_std_test"
                        :name_colspan="2"
                    />
                    <device-table-row
                        algebra="夏期標準"
                        :value="electricHeatPumpsSystemCertificates.theta_bw_sum_std_test"
                        :name_colspan="2"
                    />
                    <device-table-row
                        algebra="冬期標準"
                        :value="electricHeatPumpsSystemCertificates.theta_bw_win_std_test"
                        :name_colspan="2"
                    />
                    <device-table-row
                        name="ヒートポンプ消費電力を求める回帰式"
                        algebra="係数Ap"
                        :value="electricHeatPumpsSystemCertificates.A_p"
                        :name_rowspan="2"
                        :name_colspan="2"
                    />
                    <device-table-row
                        algebra="係数Bp"
                        :value="electricHeatPumpsSystemCertificates.B_p"
                        :name_colspan="2"
                    />
                    <device-table-row
                        name="補機消費電力"
                        algebra="ヒートポンプ運転時"
                        :value="electricHeatPumpsSystemCertificates.P_aux_HP_on_test"
                        :name_rowspan="2"
                        :name_colspan="2"
                    />
                    <device-table-row
                        algebra="ヒートポンプ停止時"
                        :value="electricHeatPumpsSystemCertificates.P_aux_HP_off_test"
                        :name_colspan="2"
                    />
                    <device-table-row
                        name="給湯保温モード貯湯損失熱量"
                        :value="electricHeatPumpsSystemCertificates.Q_loss_test"
                        :name_colspan="3"
                    />
                    <device-table-row
                        name="貯湯タンク総括熱抵抗"
                        :value="electricHeatPumpsSystemCertificates.R_tnk_test"
                        :name_colspan="3"
                    />
                    <device-table-row
                        name="試験時の冬期M1スタンダードモード沸上げ温度"
                        algebra="ファーストモード"
                        :value="electricHeatPumpsSystemCertificates.theta_hat_bw_win_cm_test_first"
                        :name_rowspan="2"
                        :name_colspan="2"
                    />
                    <device-table-row
                        algebra="セカンドモード"
                        :value="electricHeatPumpsSystemCertificates.theta_hat_bw_win_cm_test_second"
                        :name_colspan="2"
                    />
                    <device-table-row
                        name="試験手順書のバージョン"
                        :value="electricHeatPumpsSystemCertificates.standard_version"
                        :name_colspan="3"
                    />
                    <device-table-row
                        name="運用管理要綱のバージョン"
                        :value="electricHeatPumpsSystemCertificates.operation_version"
                        :name_colspan="3"
                    />
                    <device-table-row
                        name="備考"
                        :value="electricHeatPumpsDetail.note"
                        :name_colspan="3"
                    />
                </table>
                <div class="ly_buttonBox">
                    <app-button
                        name="戻る"
                        @click="back"
                        ref="jest-electric-heat-pumps-detail-back-button"
                        class="cancel"
                    />
                    <app-button
                        v-if="isShowCertificate"
                        name="成績証明書又は自己適合宣言書を確認"
                        @click="getFile"
                        ref="jest-electric-heat-pumps-detail-pdf-button"
                    />
                    <app-button
                        v-if="isChangeablePublishDate"
                        name="情報公開日等の変更"
                        @click="updatePublishDate"
                        ref="jest-electric-heat-pumps-detail-change-publish-date"
                    />
                    <app-button
                        v-if="isEnabled"
                        name="承認"
                        @click="approveEnableDevice"
                        ref="jest-electric-heat-pumps-detail-enable-button"
                    />
                    <app-button
                        name="申請"
                        @click="toPublish"
                        v-if="isAllowedToPublish"
                        ref="jest-electric-heat-pumps-detail-to-publish"
                    />
                    <app-button
                        name="承認"
                        v-if="isAllowedToApprove"
                        @click="toApprove"
                        ref="jest-electric-heat-pumps-detail-to-approve"
                    />
                    <div
                        v-show="showDisableButtons"
                        class="uq_foldedContainer">

                        <app-button
                            v-if="isDelete"
                            name="削除"
                            class="delete"
                            @click="deleteDevice"
                            ref="jest-electric-heat-pumps-detail-delete-button"
                        />
                        <app-button
                            name="削除"
                            @click="toDelete"
                            class="delete"
                            v-if="isAllowedToDelete"
                            ref="jest-electric-heat-pumps-detail-to-delete"
                        />
                        <app-button
                            name="取下"
                            @click="toCancel"
                            class="delete"
                            v-if="isAllowedToCancel"
                            ref="jest-electric-heat-pumps-detail-to-cancel"
                        />
                        <app-button
                            name="取下申請"
                            v-if="isAllowedToPublish"
                            @click="toPublishAndCancel"
                            class="delete"
                            ref="jest-electric-heat-pumps-detail-publish-cancel"
                        />
                    </div>
                    <app-button
                        v-if="isDelete || isAllowedToDelete || isAllowedToCancel || isAllowedToPublish"
                        name="…"
                        @click="toggle"
                        ref="foo"
                        class="uq_togglebutton textonly"
                    />
                </div>
            </div>
            
            <!-- PDFダウンロード用に実在する要素が必要 -->
            <div id="invisible"></div>
        </div>
    </default-template>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { move, isSet, isSetNumber, isSetString } from '@/util'
import moment from 'moment'
import DeviceTableRow from '@/components/molecules/DeviceTableRow.vue'
import pdf from "@/mixins/pdf"
import Progress from '@/mixins/progress.js'
/**
 * P017 電気ヒートポンプ給湯機詳細（一般）
 * P154 電気ヒートポンプ給湯機詳細（事業者）
 * 
 * [算出プロパティ]
 * packageName ... 表示している機器の"ブランド事業者名、ヒートポンプユニット番号、貯湯ユニット品番、補助熱源機品番、成績証明書番号"
 * date ... 文字列の年月日をYYYY/MM/DDの形に成形する
 * isPublic...publicのルートか否かを判定(表示と遷移に使用)
 * isOrganization...事業者ページか否か
 * isChangeablePublishDate...公開ページでなく、情報公開日が来ていない機器であることを判定（情報公開日の変更可否の判定）
 * 
 * [イベント処理]
 * copy ... 表示している機器のブランド事業者名、ヒートポンプユニット番号、貯湯ユニット品番、補助熱源機品番、成績証明書番号のテキストをクリップボードにコピーする
 * back ... 表示中のページに対応した戻り先へ遷移する（一般、事業者、管理者）
 * getFile ... 当該機器データに紐づく成績証明書等PDFを別ウィンドウで表示する
 */
export default {
    name:'ElectricHeatPumpsDetail',
    components: {
        DeviceTableRow
    },
    data(){
        return {
            showDisableButtons:false,
        }
    },
    mixins:[pdf, Progress],
    async mounted(){
        const loader = this.$loading.show()
        this.startProgress()
        // 機器詳細データを取得する
        await this.getDeviceDetail({id:this.$route.params.id, isPublic:this.isPublic})
        // データを取得できなかった場合はNot Foundを表示
        if (Object.keys(this.electricHeatPumpsDetail).length == 0) {
            move(this.$router, '/NotFound')
        } else {
            await this.getElectricHeatPumpsCertificate(this.electricHeatPumpsDetail.electric_heat_pump_certificate_id)
        }
        this.finishProgress()
        loader.hide()
    },
    computed:{
        ...mapGetters({
            electricHeatPumpsDetail:'electricHeatPumps/electricHeatPumpsDetail',
            electricHeatPumpsSystemCertificates:'electricHeatPumps/electricHeatPumpsSystemCertificates',
            organization:'auth/organization',
        }),
        packageName(){
            // 取得したデータを元に設定する
            // return "ブランド事業者名 ヒートポンプユニット番号 貯湯ユニット品番 補助熱源機品番 成績証明書番号"
            return this.brandName + ' '
                 + isSetString(this.electricHeatPumpsDetail.system_number)  + ' '
                 + isSetString(this.electricHeatPumpsDetail.pu_number)  + ' '
                 + isSetString(this.electricHeatPumpsDetail.tu_number)  + ' '
                 + isSetString(this.electricHeatPumpsDetail.certificate_number)
        },
        packageNameForCopy(){
            // 取得したデータを元に設定する
            // return "ブランド事業者名 ヒートポンプユニット番号 貯湯ユニット品番 補助熱源機品番 成績証明書番号"
            return this.brandName
                 + isSetNumber(this.electricHeatPumpsDetail.system_number)
                 + isSetNumber(this.electricHeatPumpsDetail.pu_number)
                 + isSetNumber(this.electricHeatPumpsDetail.tu_number)
                 + isSetNumber(this.electricHeatPumpsDetail.certificate_number)
        },
        /**
         * 文字列の年月日をYYYY年MM月DD日の形に成形する
         */
        date(){
            return function(dateString) {
                return moment(dateString).format('YYYY年MM月DD日')
            }
        },
        /**
         * publicのルートか否かを判定(表示と遷移に使用)
         */
        isPublic(){
            return this.$route.meta.noGuard ?? false
        },
        /**
         * 事業者ページか否か
         */
        isOrganization() {
            return this.$route.meta.routeType === 'organization'
        },
        /**
         * 管理者ページか否か
         */
        isAdministrator() {
            return this.$route.meta.routeType === 'administrator'
        },
        /**
         * 公開ページでなく、情報公開日が来ていない機器であることを判定
         * （情報公開日の変更可否の判定）
         */
        isChangeablePublishDate(){
            return this.isOrganization
        },
        /**
         * 詳細画面か否かの判定
         */
        isDetail() {
            return this.$route.meta.type === 'detail'
        },
        /**
         * 削除画面か否かの判定
         */
        isDelete() {
            return this.$route.meta.type === 'delete'
        },
        /**
         * 承認画面か否かの判定
         */
        isEnabled() {
            return this.$route.meta.type === 'approvedEnabled'
        },
        /**
         * 成績証明書の表示可否判定
         */
        isShowCertificate(){
            return (this.isDetail || this.isEnabled) && this.cannotShowCertificate === false
        },
        /**
         * ステータスによる表示不可判定
         * （一般ページかつ取下げ公開中の場合true）
         */
        cannotShowCertificate(){
            return this.isPublic && this.electricHeatPumpsDetail.status === 5
        },
        /**
         * 機器の申請・取下申請の可否を返す（事業者のみ）
         * （機器が公開申請前かつ機器を表示している事業者がブランド事業者である場合true）
         */
        isAllowedToPublish(){
            return this.isDetail && this.isOrganization && this.electricHeatPumpsDetail.status === 1 && this.isBrandUser
        },
        /**
         * 機器の削除の可否を返す
         * （事業者としての削除条件または管理者としての削除条件を満たしていればtrue）
         */
        isAllowedToDelete(){
            return this.isDetail && (this.isOrganizationAllowedToDelete ||this.isAdministratorAllowedToDelete)
        },
        /**
         * 機器の削除の可否を返す（事業者）
         * （事業者の場合、機器が公開申請前または「機器が公開申請済みかつ機器を表示している事業者がブランド事業者である」場合true）
         */
        isOrganizationAllowedToDelete(){
            return this.isOrganization && (this.electricHeatPumpsDetail.status === 1 || (this.electricHeatPumpsDetail.status === 2 && this.isBrandUser))
        },
        /**
         * 機器の削除の可否を返す（管理者）
         * （管理者の場合、公開申請済、公開承認済、一般公開中、取下公開中のいずれかのステータスの場合true）
         */
        isAdministratorAllowedToDelete(){
            const statusAbleToDelete = [2, 3, 4, 5]
            return this.isAdministrator && statusAbleToDelete.includes(this.electricHeatPumpsDetail.status)
        },
        /**
         * 機器の取下の可否を返す（事業者のみ）
         * （ステータスが公開申請済・公開承認済・一般公開中のいずれかかつ機器を表示している事業者がブランド事業者であり、
         * さらにまだ取下げフラグがfalseである場合はtrue）
         */
        isAllowedToCancel(){
            const statusAbleToCancel = [2, 3, 4]
            return this.isDetail && this.isOrganization && statusAbleToCancel.includes(this.electricHeatPumpsDetail.status)
                && this.isBrandUser && this.electricHeatPumpsDetail.canceled === false
        },
        /**
         * 機器の承認の可否を返す（管理者のみ）
         * （ステータスが公開申請済である場合true）
         */
        isAllowedToApprove(){
            return this.isDetail && this.isAdministrator && this.electricHeatPumpsDetail.status === 2
        },
        /**
         * 機器のブランド事業者IDがログイン中の事業者IDと一致するか否か
         */
        isBrandUser() {
            return this.electricHeatPumpsDetail.brand_name.organization_id == this.organization.id
        },
        brandName(){
            return isSet(this.electricHeatPumpsDetail.brand_name) ? this.electricHeatPumpsDetail.brand_name.name : null
        },
        isSoftDelete(){
            const statusForSoftDelete = [3, 4, 5]
            return this.isAdministrator && this.isDelete && statusForSoftDelete.includes(this.electricHeatPumpsDetail.status)
        }
    },
    methods:{
        ...mapActions({
            getDeviceDetail:'electricHeatPumps/getDeviceDetail',
            getElectricHeatPumpsCertificate:'electricHeatPumps/getElectricHeatPumpsCertificate',
            getDeviceCertificateFile:'electricHeatPumps/getDeviceCertificateFile',
            deleteElectricHeatPumps:'electricHeatPumps/deleteDevice',
            approveEnableElectricHeatPumps:'electricHeatPumps/approveEnableDevice',
            approveDisableElectricHeatPumps:'electricHeatPumps/approveDisableDevice',
            clearError:'error/clear',
            updateError:'error/update',
        }),
        /*
         * 表示している機器の"ブランド事業者名 ヒートポンプユニット番号 貯湯ユニット品番 補助熱源機品番 成績証明書番号"のテキストをクリップボードにコピーする
         */
        copy(){
            this.$copyText(this.packageNameForCopy)
            alert("コピーしました。")
        },
        /**
         * 表示中のページに対応した戻り先へ遷移する（一般、事業者、管理者）
         */
        back(){
            if (this.isPublic) {
                move(this.$router, '/electric_heat_pumps')
            } else if (this.isOrganization) {
                move(this.$router, '/organization/electric_heat_pumps')
            } else if (this.isAdministrator) {
                move(this.$router, '/admin/electric_heat_pumps')
            }
        },
        /**
         * 情報公開日等の変更ページに遷移
         */
        updatePublishDate(){
            move(this.$router, '/organization/electric_heat_pumps/' + this.electricHeatPumpsDetail.id + '/update/publish_date')
        },
        /**
         * 当該機器データに紐づく成績証明書等PDFを別ウィンドウで表示する
         */
        async getFile(){
            const file = await this.getDeviceCertificateFile(this.electricHeatPumpsDetail.electric_heat_pump_certificate_id)
            this.openPdf(file, 'Certification')
        },
        /**
         * 電気ヒートポンプ給湯機の削除実行
         */
        deleteDevice() {
            this.clearError()
            return this.deleteElectricHeatPumps()
                .then(response => {
                    if (response.status == 200) {
                        this.updateError(response)
                        this.back()
                    } else {
                        this.updateError(response)
                    }
                })
        },
        approveEnableDevice() {
            this.clearError()
            return this.approveEnableElectricHeatPumps()
                .then(response => {
                    if (response.status == 200) {
                        this.updateError(response)
                        this.back()
                    } else {
                        this.updateError(response)
                    }
                })
        },
        approveDisableDevice() {
            this.clearError()
            return this.approveDisableElectricHeatPumps()
                .then(response => {
                    if (response.status == 200) {
                        this.updateError(response)
                        this.back()
                    } else {
                        this.updateError(response)
                    }
                })
        },
        /**
         * 機器の削除画面に遷移
         */
        toDelete(){
            this.clearError()
            let url
            if (this.isOrganization) {
                url = '/organization/electric_heat_pumps/' + this.electricHeatPumpsDetail.id + '/delete'
            } else if (this.isAdministrator) {
                url = '/admin/electric_heat_pumps/' + this.electricHeatPumpsDetail.id + '/delete'
            }
            move(this.$router, url)
        },
        /**
         * 機器の公開申請画面に遷移（事業者のみ）
         */
        toPublish(){
            this.clearError()
            move(this.$router, '/organization/electric_heat_pumps/' + this.electricHeatPumpsDetail.id + '/status/published')
        },
        /**
         * 機器の取下画面に遷移（事業者のみ）
         */
        toCancel(){
            this.clearError()
            move(this.$router, '/organization/electric_heat_pumps/' + this.electricHeatPumpsDetail.id + '/status/canceled')
        },
        /**
         * 機器の取下申請画面に遷移（事業者のみ）
         */
        toPublishAndCancel(){
            this.clearError()
            move(this.$router, '/organization/electric_heat_pumps/' + this.electricHeatPumpsDetail.id + '/status/published/cancel')
        },
        /**
         * 機器の承認画面に遷移（管理者のみ）
         */
        toApprove(){
            this.clearError()
            move(this.$router, '/admin/electric_heat_pumps/' + this.electricHeatPumpsDetail.id + '/approved/enabled')
        },
        /**
         * 削除・取り下げのボタンを…ボタンで表示切替
         */
        toggle(){
            this.showDisableButtons = !this.showDisableButtons
        },
    }
}
</script>

<style scoped>
.bl_contents_wrapper{
    max-width: 800px;
}
.uq_width_fitter{
    width: 100%;
}
.bl_eleHeatPump_table table{
    width: calc(100% - 16px);
    min-width: 800px;
}
.bl_eleHeatPump_table >>> tr td:nth-last-child(2){
    min-width: 12em;
}
.bl_underHeader >>> button.el_button.uq_btnCopy{
    width: 100px;
    min-width: 100px;
    height: 24px;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0.25em 1em;
    font-weight: bolder;
    border-radius: 13px;
    background-color: white;
}
.bl_underHeader >>> button.el_button.uq_btnCopy:hover{
    color: #aaa;
}

.red_bold{
    color:#F00;
    font-weight: bold;
    font-size:1.2em;
}
</style>